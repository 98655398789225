export const UNDER_MAINTENANCE = false;

export interface IFullIndexConfig {
    create_document_types: {
      enabled: boolean;
      document_types: any[];
    };
    solr_create_collections: {
      enabled: boolean;
      collections: string[];
    };
    solr_bootstrap: {
      enabled: boolean;
      do_field_types: boolean;
      document_types: string[];
      do_regulations: boolean;
    };
    solr_batch_index: {
      enabled: boolean;
      limit: number;
      max_fails: number;
      force: boolean;
      document_ids: string[];
      collections: string[];
    };
    milvus_bootstrap: {
      enabled: boolean;
      collections: string[];
    };
    batch_vectorize: {
      enabled: boolean;
      limit: number;
      max_fails: number;
      force: boolean;
      document_ids: string[];
      collections: string[];
    };
    batch_index_regulations: {
      enabled: boolean;
      limit: number;
    };
    extract_entities: {
      enabled: boolean;
      facet_limit: number;
      doc_limit: number;
      doc_ids: string[];
      force: boolean;
      clean_ignore_list: boolean;
      document_types: string[];
    };
  }