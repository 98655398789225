import { Button } from "@mui/material/";
import { Field, Form, Formik } from "formik";
import * as React from "react";
import { useDispatch } from "react-redux";
import { CheckCircleOutline, ErrorOutline } from "@mui/icons-material"; // Import MUI icons
import * as Yup from "yup";
import { passwordResetAction } from "../../actions/userActions";
import { SigninField } from "../Signin/SigninField";
import "./ForgotPassword.css"; // Import CSS

// Define interface for form values
export interface Values {
    email: string;
}

const ForgotPassword: React.FC = () => {
    const [successView, setSuccessView] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null); // To handle error messages
    const dispatch = useDispatch();

    const reset = (values: Values) => {
        // Dispatch the password reset action with success and error callbacks
        dispatch(
            passwordResetAction(
                { email: values.email },
                () => {
                    setSuccessView(true);  // Show success view on success
                    setErrorMessage(null); // Clear any existing errors
                },
                (error) => {
                    setErrorMessage(error.message || "An unexpected error occurred. Please try again.");
                    setSuccessView(false); // Make sure success view is not shown on error
                },
                "Password reset email sent",
                true
            )
        );
    };

    // Render success view w
    if (successView) {
        return (
            <div className="SuccessContainer">
                <CheckCircleOutline className="icon successIcon" /> 
                <p>Success! If an account with that email exists, you will receive a link to reset your password.</p>
            </div>
        );
    }

    // Render error view 
    if (errorMessage) {
        return (
            <div className="ErrorContainer">
                <ErrorOutline className="icon errorIcon" /> 
                <p>{errorMessage}</p>
            </div>
        );
    }

    return (
        <div className="LoginContainer">
            <Formik
                initialValues={{ email: "" }}
                validationSchema={Yup.object().shape({
                    email: Yup.string()
                        .email("Email is invalid")
                        .required("Email is required"),
                })}
                onSubmit={(values) => {
                    reset(values);
                }}
            >
                {({ touched, errors }) => (
                    <Form className="FormContainer">
                        <h2>Password Reset</h2>
                        <div>Please enter your email address.</div>
                        <div className="fields">
                            <Field name="email" placeholder="Email" component={SigninField} />
                        </div>
                        <Button className="signinButton" type="submit">Submit</Button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default ForgotPassword;
