import { isObject } from "lodash";
import { actionIds } from "../../actions/actionIds";
import { autoReduce } from "./auto-reduce";
import { IMainState } from "../../interfaces/rootState";
import { IDocumentReference } from "../../stack-shared/interfaces/model/document-reference.interface";
import { ISystemSettings } from "../../stack-shared/interfaces/model/system-settings.interface";
import { IDocumentReferenceType } from "../../stack-shared/interfaces/model/document-reference-type.interface";

function getTopOccurrences(arr: any) {
  // Filter out undefined values before processing
  const filteredArr = arr.filter((item: any) => item !== undefined);

  const counts = filteredArr.reduce((acc: any, cur: any) => {
      acc[cur] = (acc[cur] || 0) + 1;
      return acc;
  }, {});

  return Object.entries(counts)
      .sort((a: any, b: any) => b[1] - a[1]) // Sort by occurrence, descending
      .slice(0, 10) // Keep only top 10
      .map(([title, count]) => ({ title, count, checked: true })); // Return objects with title and count
}

const loggedUser = {} as any;
let socketId = null
if (window?.localStorage?.email) {
  loggedUser.email = window?.localStorage?.email;
  loggedUser.name = window?.localStorage?.name;
  loggedUser.id = window?.localStorage?.id;
  loggedUser.isAdmin = window?.localStorage?.isAdmin;
}

if (window?.localStorage?.socketId) {
  socketId = window.localStorage.socketId;
}

const initialState = {
  solrResults: {} as any,
  caseLawResults: {} as any,
  solrQuick: {} as any,
  solrRelated: {} as any,
  solrRegulations: {} as any,
  userInfo: {
    results: []
  } as any,
  errorMessage: {} as any,
  error: {} as any,
  success: {} as any,
  document_references: {} as { [key: string]: IDocumentReference },
  documentReferenceTypes: {} as { [key: string]: IDocumentReferenceType },
  socketId,
  loading: false,
  loggedUser: loggedUser as any,
  aiResult: {} as any,
  aiResultCaseLaw: {} as any,
  system_settings: {} as ISystemSettings,
};

const mainState = (state: any = initialState, action: any): IMainState => {
  if (action.normalized || action.removeKeys || action.overrides || action.mergeKeys) {
    return autoReduce(action, state);
  }
  // console.log("DEBUG", action.type)
  switch (action.type) {
    case actionIds.ERROR:
      if (isObject(action.payload)) {
        state.error = action.payload;
        return { ...state } as any;
      }
      break;
    case actionIds.SUCCESS:
      if (isObject(action.payload)) {
        state.success = action.payload;
        return { ...state } as any;
      }
      break;
    case actionIds.LOGOUT:
      window.localStorage.clear();
      return { ...initialState,
        loggedUser: {}
       } as any;
    case actionIds.SIGNIN_SUCCESS:
      const { data: successData } = action.payload;
      window.localStorage.jwt = successData.jwt;
      window.localStorage.cookie = successData.cookie;
      window.localStorage.email = successData?.data?.email;
      window.localStorage.name = successData?.data?.displayName;
      window.localStorage.id = successData?.data?.id;
      window.localStorage.socketId = successData.socketId;
      console.log("should set it", successData)
      let isAdmin = false;
      if (successData.isAdmin) {
        window.localStorage.isAdmin = true;
        isAdmin = true;
      }
      const now = new Date();
      now.setTime(now.getTime() + 1 * 3600 * 1000);
      window.localStorage.expires = now;
      return {
        ...state,
        loggedUser: {
          email: successData?.data?.email,
          id: successData?.data?.id,
          name: successData?.data?.displayName,
          isAdmin
        },
        socketId: successData.socketId
      };
    case actionIds.CLEAR_SEARCH_CACHE:
      return {
        ...state,
        solrResults: null,
        caseLawResults: null,
      };;
    case actionIds.SOLR_SEARCH_SUCCESS:
      if (action.data.response && action.data.response.docs) {

          if (action.data?.facet_counts?.facet_fields?.entity_person){
            action.data.entity_person = action.data.facet_counts.facet_fields.entity_person.slice(0, 10).map((x: any) => {
              return {
                title: x.title,
                count: x.count,
                checked: true
              }
            })
          }
          if (action.data?.facet_counts?.facet_fields?.entity_organization){
            action.data.entity_organization = action.data.facet_counts.facet_fields.entity_organization.slice(0, 10).map((x: any) => {
              return {
                title: x.title,
                count: x.count,
                checked: true
              }
            })
          }
          if (action.data?.facet_counts?.facet_fields?.entity_location){
            action.data.entity_location = action.data.facet_counts.facet_fields.entity_location.slice(0, 10).map((x: any) => {
              return {
                title: x.title,
                count: x.count,
                checked: true
              }
            })
          }
      

        action.data.response.docs.forEach((doc: any) => {
          doc.highlighting = action.data.highlighting[doc.id];
        });
      }
      return {
        ...state,
        solrResults: action.data,
      };
    case actionIds.CASE_LAW_SEARCH_SUCCESS:
      if (action.data.response && action.data.response.docs) {
        action.data.response.docs.forEach((doc: any) => {
          doc.highlighting = action.data.highlighting[doc.id];
        });


          if (action.data?.facet_counts?.facet_fields?.entity_person){
            action.data.entity_person = action.data.facet_counts.facet_fields.entity_person.slice(0, 10).map((x: any) => {
              return {
                title: x.title,
                count: x.count,
                checked: true
              }
            })
          }
          if (action.data?.facet_counts?.facet_fields?.entity_organization){
            action.data.entity_organization = action.data.facet_counts.facet_fields.entity_organization.slice(0, 10).map((x: any) => {
              return {
                title: x.title,
                count: x.count,
                checked: true
              }
            })
          }
          if (action.data?.facet_counts?.facet_fields?.entity_location){
            action.data.entity_location = action.data.facet_counts.facet_fields.entity_location.slice(0, 10).map((x: any) => {
              return {
                title: x.title,
                count: x.count,
                checked: true
              }
            })
          }

      }
      return {
        ...state,
        caseLawResults: action.data,
      };
    case actionIds.SOLR_QUICK_SUCCESS:
      return {
        ...state,
        solrQuick: action.data,
      };
    case actionIds.LOGIN_ERROR:
      return {
        ...state,
        errorMessage: { error: "Incorrect Password. Please Try Again." },
      };
    case actionIds.PASSWORD_ERROR:
      return {
        ...state,
        errorMessage: { error: "This Email does not exist in our system. Please Try Again." },
      };
    case actionIds.SOLR_REGULATIONS_SUCCESS:
      return {
        ...state,
        solrRegulations: action.data,
      };
    case actionIds.SOLR_RELATED_SUCCESS:
      return {
        ...state,
        solrRelated: action.data,
      };
    case actionIds.GET_USER_SUCCESS:
      return {
        ...state,
        userInfo: action.data,
      };
      case actionIds.CLEAR_AI_RESULT:
        return {
          ...state,
          aiResult: {
            context: '',
            source_nodes: [],
            text: '',
            uuid: action.payload.uuid
          },
        };
      case actionIds.CLEAR_AI_RESULT_CASE_LAW:
        return {
          ...state,
          aiResultCaseLaw: {
            context: '',
            source_nodes: [],
            text: '',
            uuid: action.payload.uuid
          },
        };
    case actionIds.SET_AI_RESULT:
      let currentSetValue = state.aiResult;
      let newState = {
        ...state
      }
      if (currentSetValue?.uuid && currentSetValue?.uuid === action?.payload?.compare_uuid){
        newState.aiResult = action.payload;
      }
      return newState;
    case actionIds.SET_AI_RESULT_CASE_LAW:
      let currentSetValueCaseLaw = state.aiResultCaseLaw;
      let newStateCaseLaw = {
        ...state
      }
      if (currentSetValueCaseLaw?.uuid && currentSetValueCaseLaw?.uuid === action?.payload?.compare_uuid){
        newStateCaseLaw.aiResultCaseLaw = action.payload;
      }
      return newStateCaseLaw;
    case actionIds.MERGE_AI_RESULT:
      let currentValue = state.aiResult;
      // if (!currentValue?.uuid) {
      //   console.log("CREATING NEW VALUE MSHA")
      //   currentValue = {
      //     context: '',
      //     source_nodes: [],
      //     text: '',
      //     uuid: ''
      //   }
      // }
      // only stream to a matching uuid, otherwise ignore the message
      if (currentValue?.uuid && currentValue?.uuid === action?.payload?.uuid){
        //console.log("merging MSHA result set text")
        currentValue.text += action?.payload?.text || "";
      }
      return {
        ...state,
        aiResult: { ...currentValue },
      };
    case actionIds.MERGE_AI_RESULT_CASE_LAW:
      let currentValueCaseLaw = state.aiResultCaseLaw;
      // if (!currentValueCaseLaw?.uuid) {
      //   console.log("CREATING NEW VALUE CASE LAW")
      //   currentValueCaseLaw = {
      //     context: '',
      //     source_nodes: [],
      //     text: '',
      //     uuid: ''
      //   }
      // }
      // only stream to a matching uuid, otherwise ignore the message
      if (currentValueCaseLaw?.uuid && currentValueCaseLaw?.uuid === action?.payload?.uuid){
        currentValueCaseLaw.text += action?.payload?.text || "";
      }
      return {
        ...state,
        aiResultCaseLaw: { ...currentValueCaseLaw },
      };
      case actionIds.START_LOADING_SUCCESS:
        state.loading = true;
        return { ...state };
      case actionIds.STOP_LOADING_SUCCESS:
        state.loading = false;
        return { ...state };
  }
  return state;
};

export default mainState;